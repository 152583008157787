* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  color: #333e5a;
  min-height: 100%;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  height: calc(var(--vh, 1vh) * 100);
}

.app {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
}



main {
  background: #ffffff;
  flex-grow: 1;
}

form {
  max-width: 300px;
  margin: 0 auto;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

form > div {
  width: 100%;
  margin-bottom: 1em;
}
form > div > label {
  display: block;
  margin-bottom: 0.3em;
}
form > div > input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #333e5a;
}

button {
  background: #333e5a;
  color: #fff;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid transparent;
}
button:hover {
  filter: brightness(150%);
}

.room {
  position: relative;
}
.room button {
  /*position: absolute;*/
  top: 0;
  right: 20px;
}
.room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.local-participant {
  text-align: center;
  margin-bottom: 2em;
}
.remote-participants {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0 2em 2em;
}

.participant:last-child {
  margin-right: 0;
}
.participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #43405d;
}

video {
  width: 100%;
  max-width: 600px;
  max-height: 30vh;
  display: block;
  margin: 0 auto;
  border-radius: 6px;
}

.appContainer {
  display: flex;
  flex-direction: column;
  height: calc(100 * var(--vh));
}

header, main, footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

header {
  flex: 0.05;
}

main {
  flex: 0.90;
}

footer {
  flex: 0.1;
  background: #43405d;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  position: sticky;
  bottom: 0;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.iconButton {
  background-color: #3c376b;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.iconButton:hover {
  background-color: #ab1f2f;
}

.name-input {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  display: block;
}

.inactivityModal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.inactivityModal {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

.inactivityModal button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  font-size: 16px;
  transition: background-color 0.3s;
}

.inactivityModal button:hover {
  background-color: #0056b3;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3); /* Color claro */
  border-radius: 50%; /* Para hacerlo circular */
  border-top: 8px solid black; /* Color oscuro */
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.spinnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
  border-radius: 30px !important;
  background-color: #3c376b;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (max-width: 768px) {
  video {
    max-height: 25vh;
  }

  footer {
    padding-bottom: calc(5rem + env(safe-area-inset-bottom));
  }

}
